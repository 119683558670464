
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body {
	background-color: white;
}

.navbar {
	margin-bottom:0;
}

.navbar-flex {
	display: flex;
	justify-content: space-between;
	border:0;
}

.navbttn {
	height:50px;
}

.welcome {
	margin:20px;
	padding:25px;
	border:1px solid black;
	display:flex;
	flex-direction: column;
}

.welcome p {
	font-size:1.75em;
}

.bringing-list {
	font-size:1.5em;
}

.vote_count {
	font-size:1.4em;
	text-align: center;
}

h4 {
    margin:0px;
}

.logo-text-top {
	font-size:3em;
    font-weight: bold;
}

.logo-text-bottom {
    font-size: 36px;
    font-weight: bold;
}

.bar {
     display: flex;
     align-items: center;
     justify-content: space-around;
     width: 100%;
     padding: 10px;
}

.tableStyleFontSize {
    font-size:1.2em;
}

#blog {
	margin:auto;
	max-width: 960px;
}

.blog-article {
	margin:25px;
	padding:0;
}

.blog-article-title {
	font-size:2em;
	font-weight:bold;
}

.blog-article-deets {
	font-size:1.2em;
}

.blog-article-body {
	margin-top: 1.25em;
	margin-left:2em;
}

.blog-article p {
	font-size: 1.45em;
}